export enum API_ENDPOINT {
  LOGIN_MEDICO = "/login/medico",
  LOGIN_PACIENTE = "/login/paciente",
  LOGIN = "/login",
  RESET = "/password/reset/",
  CHECK_TOKEN = "/password/reset/:TOKEN",
  CHANGE_PASS = "/password/change",
  LOGININFORME = "/informe",
}

export const TOKEN_HEADER = "Authorization";
export enum keys {
  TOKEN_HEADER = "Authorization",
  USERNAME = "_username",
  USERID = "_userid",
  USER_TYPE = "userType",
  REGISTRADO = "registrado",
  USUARIO_EXTERNO_ID = "userExternId",
}

export enum LOGIN {
  LOGIN_LOGO = "logo_Kern_270x82.png", //"logo_login.png",
  LOGIN_FUNDO = "", //"fondo.jpg",
}

